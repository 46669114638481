import { Button, Form } from "react-bootstrap"
import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { graphql, StaticQuery } from 'gatsby';


class FormEight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e) => {
    this.props.handleChange(e.target.value, 'meer_informatie');
  }
  handleSubmit = (e) => {
    this.props.goToNextPage();
  }

  render() {
    const data = this.props.data.contentfulStartBoekhouderForm;

    return (
      <div className="">
        <div className="mainFormContent">
          <div className="descriptionForm">
            <h2>{data.step7Heading}</h2>
            <textarea className="mb-3 form-control" name="dataVal" rows="6" onChange={this.handleChange}>
            </textarea>

          </div>
        </div>
        <div className="form-bottom">
          <Button className="btn orange-btn transparent-btn float-left" onClick={this.props.goToPrevPage()}><FontAwesomeIcon icon={faArrowLeft} className="mr-1" />Vorige</Button>
          {<Button className="btn orange-btn" type="button" onClick={() => this.handleSubmit()}>{data.step7NextButtonText}</Button>}
        </div>
      </div>
    )

  }

}



FormEight.propTypes = {
  data: PropTypes.object,
}

export default function FormEleData(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulStartBoekhouderForm {
            step7Heading
            step7NextButtonText
          }
        }
      `}
      render={data => <FormEight data={data} {...props} />}
    />
  )
}