import { Button, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { graphql, StaticQuery } from 'gatsby';


class FormNine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loader:false};
  }

  componentDidMount(){
    this.setState({ loader:true })
    setTimeout(
      function() {
          this.setState({ loader:false });
      }
      .bind(this),
      2000
    );
  }

  handleSubmit = () => {
    this.props.goToFinalPage();
  }

  render() {
    const data = this.props.data.contentfulStartBoekhouderForm;
    
    return (
      <div>
        <div className="mainFormContent" style={{ position: 'relative' }}>
          {this.state.loader ? 
          <div className="loader-min"><div className="loader"></div></div>
          : ''}
          <h2>{data.step8Heading}</h2>
          
            {data.step8ImageBlocks.map((imageblock, index) => (
            <Row className="product-m-div">
              <Col md={{span: 6, offset: ((index%2)===0 ? 6 : 0)}}>
                <div className="prduct-m-img">
                  <img src={imageblock.image.fluid.src} alt="clientImg" />
                  <div className="product-m-title">
                      <strong>{imageblock.name}</strong>
                      <span>{imageblock.text}</span>
                  </div>
                </div>
              </Col>
            </Row>
            ))}
          
        </div>
        <div className="form-bottom">
          <Button className="btn orange-btn transparent-btn float-left" onClick={this.props.goToPrevPage()}><FontAwesomeIcon icon={faArrowLeft} className="mr-1" />Vorige</Button>
          {<Button className="btn orange-btn" type="button" onClick={() => this.handleSubmit()}>{data.step8NextButtonText}</Button>}
        </div>
      </div>
    )
  }
}

FormNine.propTypes = {
  data: PropTypes.object,
}

export default function FormNineData(props) {
  return (
    <StaticQuery
      query={graphql`
      query {
        contentfulStartBoekhouderForm {
          step8Heading
          step8ImageBlocks {
            image {
              fluid {
                src
              }
            }
            name
            text
          }
          step8NextButtonText
        }
      }
      `}
      render={data => <FormNine data={data} {...props} />}
    />
  )
}