import { Button, Form, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { graphql, StaticQuery } from 'gatsby';

class  FormThree  extends React.Component {
  constructor(props){
      super(props);
      this.state={
          error: ''
      }

      this.handleChangeRadio = this.handleChangeRadio.bind(this);
  }

  handleChangeRadio = (val) =>{
    const { werkzaamheden } = this.props.formData;
    console.log(werkzaamheden);
    const index = werkzaamheden.indexOf(val);
    if (index > -1) {
      werkzaamheden.splice(index, 1);
    }else{
      werkzaamheden.push(val)
    }
    this.props.handleChange(werkzaamheden, 'werkzaamheden');
  }
  


  handleSubmit = (e) =>{
    if(this.props.formData.werkzaamheden.length === 0){
      this.setState({
        error:'Invoer is nodig',
      })
    }
    else{
      this.props.goToNextPage();
    }
  }

  render (){
    const unique = [...new Set(this.props.data.contentfulStartBoekhouderForm.step2FieldOptions.map(item => item.choiceGroup[0]))];
  
    return( 
      <div className="">
          <div className="mainFormContent">
            <h2>{this.props.data.contentfulStartBoekhouderForm.step2Heading}</h2>
            <div style={{color:'red'}}>
                {this.state.error}
            </div> 
                
            <Row>
              {unique.map((item, i) => (
                <Col xs={12} md={6}>
                <small> {item}</small>
              
                {this.props.data.contentfulStartBoekhouderForm.step2FieldOptions.filter(value => value.choiceGroup[0] === item).map((data, index)=> {
                    return( 
                      <div>                      
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id={`radio2-${i}-${index}`}  checked={this.props.formData.werkzaamheden.includes(data.choiceText)} onChange={()=>this.handleChangeRadio(data.choiceText)} />
                            <label className="form-check-label" for={`radio2-${i}-${index}`}>{data.choiceText}</label>
                        </div>
                      </div> 
                    )
                  }
                )}
                </Col>
              ))
              }
            </Row>

          </div>
          <div className="form-bottom">
            <Button className="btn orange-btn transparent-btn float-left" onClick={this.props.goToPrevPage()}><FontAwesomeIcon icon={faArrowLeft} className="mr-1"/>Vorige</Button>
        
            {<Button className="btn orange-btn" type="button" onClick={() => this.handleSubmit()}>{this.props.data.contentfulStartBoekhouderForm.step2NextButtonText}</Button>}
        </div>
      </div>
      )

  }
  
}


FormThree.propTypes = {
  data: PropTypes.object,
}

export default function FormEleData(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulStartBoekhouderForm {
            step2FieldOptions {
              choiceText
              choiceGroup
            }
            step2Heading
            step2NextButtonText
          }
        }
      `}
      render={data => <FormThree data={data} {...props} />}
    />
  )
}