import {  Button, Form } from "react-bootstrap"
import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { graphql, StaticQuery } from 'gatsby';


class FormFour extends React.Component {
    constructor(props){
       super(props);
       this.state={
           error:''
       }

       this.handleChangeRadio = this.handleChangeRadio.bind(this);
    }
    
    handleChangeRadio = (val) =>{
        this.props.handleChange(val, 'rechtsvorm');
    }

    handleSubmit = (e) =>{
        if(this.props.formData.rechtsvorm === ''){
            this.setState({
            error:'Invoer is nodig',
            })
        }
        else{
            this.props.goToNextPage();
        }
    }

    render(){
        const data = this.props.data.contentfulStartBoekhouderForm
    
        return(
        <div className="">
            <div className="mainFormContent">
                <h2>{data.step3Heading}</h2>
                <div className="text-center">
                    <span  className="small-heading d-inline" style={{color:'#C1C1C1'}}>{data.step3SubHeading}</span>
                </div>

                    
                <div className="centerForm">
                    <div style={{color:'red'}}>
                        {this.state.error}
                    </div> 

                    {data.step3FieldOptions.map((item, index) => (
                        <div className="form-check">
                            <input className="form-check-input" type="radio" id={`radio4-${index}`} onChange={() => this.handleChangeRadio(item.choiceText)} checked={this.props.formData.rechtsvorm === item.choiceText} />
                            <label className="form-check-label" for={`radio4-${index}`}>{item.choiceText}</label>
                        </div>
                    ))
                    }
                </div>
            </div>
            <div className="form-bottom">
                <Button className="btn orange-btn transparent-btn float-left" onClick={this.props.goToPrevPage()}><FontAwesomeIcon icon={faArrowLeft} className="mr-1"/>Vorige</Button>
                {<Button className="btn orange-btn" type="button" onClick={() => this.handleSubmit()}>{data.step3NextButtonText}</Button>}
            </div>
        </div>
        )
    }
}

FormFour.propTypes = {
    data: PropTypes.object,
}

export default function FormEleData(props) {
    return (
      <StaticQuery
        query={graphql`
          query {
            contentfulStartBoekhouderForm {
                step3Heading
                step3NextButtonText
                step3SubHeading
                step3FieldOptions {
                  choiceText
                }
            }
          }
        `}
        render={data => <FormFour data={data} {...props} />}
      />
    )
}