import {  Button, Form, Row, Col } from "react-bootstrap"
import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { graphql, StaticQuery } from 'gatsby';


class FormFive extends React.Component {
    constructor(props){
        super(props);
        this.state={
            error:''
        }
    }
    handleChangeRadio = (val) =>{
        this.props.handleChange(val, 'jaaromzet');
    }

    handleSubmit = (e) =>{
        if(this.props.formData.jaaromzet === ''){
            this.setState({
            error:'Invoer is nodig',
            })
        }
        else{
            this.props.goToNextPage();
        }
    }

    render(){
        const data = this.props.data.contentfulStartBoekhouderForm;

        return(
        <div className=""> 
            <div className="mainFormContent">
                <h2>{data.step4Heading}</h2>

                <div style={{color:'red'}}>{this.state.error}</div> 
                        
                <Row>
                    {data.step4FieldOptions.map((item, index) => (
                    <Col xs={12} md={6}>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" id={`radio5-${index}`} onChange={() => this.handleChangeRadio(item.choiceText)} checked={this.props.formData.jaaromzet === item.choiceText} />
                            <label className="form-check-label" for={`radio5-${index}`}>{item.choiceText}</label>
                        </div>
                    </Col>
                    ))}
                </Row>
                        
            </div>
            <div className="form-bottom">
                <Button className="btn orange-btn transparent-btn float-left" onClick={this.props.goToPrevPage()}><FontAwesomeIcon icon={faArrowLeft} className="mr-1"/>Vorige</Button>
                {<Button className="btn orange-btn" type="button" onClick={() => this.handleSubmit()}>{data.step4NextButtonText}</Button>}
            </div>
        </div>
        )
    }
}

FormFive.propTypes = {
    data: PropTypes.object,
  }

export default function FormEleData(props) {
    return (
      <StaticQuery
        query={graphql`
          query {
            contentfulStartBoekhouderForm {
                step4FieldOptions {
                  choiceText
                }
                step4Heading
                step4NextButtonText
            }
          }
        `}
        render={data => <FormFive data={data} {...props} />}
      />
    )
}