import React from "react"
import PropTypes from "prop-types"
import { Row, Col, FormControl, Button, Form } from 'react-bootstrap';
import { graphql, StaticQuery } from 'gatsby';
import { Link } from "gatsby";
import axios from 'axios';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import mailbox from "../../images/mailbox.svg"
import lock from "../../images/lock.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft, faCheckCircle} from '@fortawesome/free-solid-svg-icons';


class FormEleven extends React.Component {
  constructor(props){
    super(props);
    this.state={
        names:'',
        email:'',
        phone:'',
        checkTF:false,
        errornames: '',
        erroremail:'',
        errorphone:''
    }

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeTele = this.handleChangeTele.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeName = (e) => {
    if(e.target.value == ''){
      this.setState({ names: e.target.value, errornames: 'Invoer is nodig' });
    }
    else{
      this.setState({ names: e.target.value, errornames: '' });
    } 
  }

  handleChangeEmail = (e) => {
    var emailpattern = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4};?)+$/;
    if(emailpattern.test(e.target.value)){
      this.setState({ email: e.target.value, erroremail: '', checkTF: true });
    }
    else{
      this.setState({ email: e.target.value, erroremail: 'Invoer is nodig', checkTF: false });
    }
  }

  handleChangeTele = (e) => {
    var phonepattern = /^\d{10}$/;
    if(e.target.value.match(phonepattern)) {
      this.setState({ phone: e.target.value, errorphone: '' });
    }else{ 
      this.setState({ phone: e.target.value, errorphone: 'Invoer is nodig' });
    }
  }
 
  render() { 
    const { formData } = this.props;
  
    const data = this.props.data.contentfulStartBoekhouderForm;

    return(
    <div>
    <form method="post" action="/success" className="customForm" data-netlify-honeypot="bot-field" data-netlify="true" name="StartFormSubmit">
    <input type="hidden" name="bot-field" />
    <input type="hidden" name="form-name" value="StartFormSubmit" />
    <input type="text" className="hiddenfield" name="plaats" value={formData.plaats} />
    <input type="text" className="hiddenfield" name="werkzaamheden" value={formData.werkzaamheden.join(",")} />
    <input type="text" className="hiddenfield" name="rechtsvorm" value={formData.rechtsvorm} />
    <input type="text" className="hiddenfield" name="jaaromzet" value={formData.jaaromzet} />
    <input type="text" className="hiddenfield" name="levert_u_administratie" value={formData.levert_u_administratie} />
    <input type="text" className="hiddenfield" name="naam_bedrijf" value={formData.naam_bedrijf} />
    <input type="text" className="hiddenfield" name="meer_informatie" value={formData.meer_informatie} />
      <div className="mainFormContent">
        <h2>{data.step9Heading}</h2>
        <Row className="mt-5">
          <Col xs={12} md={6}>
            <p>{data.step9SubHeading}</p>
            <div style={{ color: 'red' }}>{this.state.errornames}</div>
            <div className="mb-5 position-relative">
              <em>{data.step9NameFieldLabel}</em>
                <FormControl
                  placeholder={data.step9NameFieldPlaceholder}
                  aria-label="Naam"
                  aria-describedby="basic-addon1"
                  onChange={this.handleChangeName}
                  value={this.state.names}
                  name="names"
                  required
                />
                <FontAwesomeIcon icon={faCheckCircle} className="validInput"/>
            </div>
                    
            <div className="mb-5 position-relative">
              <div style={{ color: 'red' }}>{this.state.erroremail}</div>
              <em>{data.step9EmailFieldLabel}</em>
              <FormControl
                placeholder={data.step9EmailFieldPlaceholder}
                aria-label="E-mailadres"
                aria-describedby="basic-addon2"
                onChange={this.handleChangeEmail}
                value={this.state.email}
                name="email"
                required
              />
              <FontAwesomeIcon icon={faCheckCircle} className="validInput"/>
              <span className="input-text">{data.step9EmailFieldHelpText}</span>
            </div>
            
            <div style={{ color: 'red' }}>{this.state.error3}</div>
            {this.state.checkTF &&
            <div className="mb-3 position-relative">
              <em>{data.step9PhoneFieldLabel}</em>
                <FormControl
                  placeholder={data.step9PhoneFieldPlaceholder}
                  aria-label="Telefoonnummer"
                  aria-describedby="basic-addon2"
                  onChange={this.handleChangeTele}
                  value={this.state.phone}
                  name="phone"
                  required
                />
                <span className="input-text">{data.step9PhoneFieldHelpText}</span>
            </div>
            }
            </Col>
            <Col xs={12} md={6} className="mailbox">
              <div className="text-center">
                <img src={mailbox} alt="mailbox" className="mailbox-img" />
              </div>
              <div className="d-flex align-items-center justify-content-end mt-5 lock-icon-text">
                <img src={lock} alt="lock-icon"  />
                {documentToReactComponents(JSON.parse(data.step9PrivacyStatement.raw))}
              </div>
            </Col>
          </Row>
      </div>
      <div className="form-bottom">
        <Button className="btn orange-btn transparent-btn float-left" onClick={this.props.goToPrevPage()}><FontAwesomeIcon icon={faArrowLeft} className="mr-1"/>Vorige</Button>
        <div className="float-right">
          <button className="btn orange-btn" type="submit">{data.step9NextButtonText}</button>
          <p>Gratis en vrijblijvend, je zit nergens aan vast.</p>
        </div>
      </div>
     </form>
    </div>
    )
  }
}


FormEleven.propTypes = {
  data: PropTypes.object,
}

export default function FormEleData(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulStartBoekhouderForm {
            step9EmailFieldLabel
            step9EmailFieldPlaceholder
            step9Heading
            step9NameFieldLabel
            step9NameFieldPlaceholder
            step9NextButtonText
            step9PhoneFieldLabel
            step9PhoneFieldPlaceholder
            step9SubHeading
            finalRedirectUrl
            step1Heading
            step2Heading
            step3Heading
            step4Heading
            step5Heading
            step6Heading
            step7Heading
            step8Heading
            step9PrivacyStatement {
              raw
            }
            step9EmailFieldHelpText
            step9PhoneFieldHelpText
          }
        }
      `}
      render={data => <FormEleven data={data} {...props} />}
    />
  )
}