import React from "react"
import { FormControl, InputGroup, Button, Form } from "react-bootstrap"
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { graphql, StaticQuery } from 'gatsby';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';

import Nlcities from '../../../data/nl';

const filter = createFilterOptions();

class FormOne extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    
    this.state = {
      open:false,
      error:'',
      popupVisible: false
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }
 
  handleChange =(e, newValue) => {
    if (typeof newValue === 'string') {
      this.props.handleChange(newValue, 'plaats');
      this.setState({ error: '', open:false });
    } else if (newValue && newValue.city) {
      this.props.handleChange(newValue.city, 'plaats');
      this.setState({ error: '', open:false });
    } else {
      this.props.handleChange('', 'plaats');
      this.setState({ error: 'Invoer is nodig', open:false });
    }
  }

  handleOpenDropdown = () => {
    this.setState({ open:true })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if(this.props.formData.plaats === "") {
      this.setState({ error: 'Invoer is nodig' });
    }else if(this.props.formData.plaats === "voornamelijk online") {
      this.setState({ error: 'Invoer is nodig' });
    }else{
      this.props.goToNextPage();
    }
  }

  handleSkip = () => {
    this.props.handleChange('voornamelijk online', 'plaats');
    this.props.goToNextPage();
  }


  handleClick() {
    if (!this.state.popupVisible) {
      console.log("!this.state.popupVisible", !this.state.popupVisible);
      // attach/remove event handler
      console.log("false state", this.state.popupVisible)
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      console.log("true state", this.state.popupVisible)
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
       popupVisible: !prevState.popupVisible,
    }));
  }
  
  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if(this.node) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    
    this.handleClick();
  }

  render() {
    
    return (

      <div>
        <Form className="customForm" onSubmit={this.handleSubmit}>
          <div className="mainFormContent RequestFormLocation">

            <h2>{this.props.data.contentfulStartBoekhouderForm.step1Heading}</h2>

            <div style={{ color: 'red', width:'300px', margin:'0 auto' }}>{this.state.error}</div>
           
            <div ref={this.setWrapperRef} style={{position:'relative', textAlign:'center'}}>

                <Autocomplete
                  freeSolo
                  id="combo-box-demo"
                  onChange={this.handleChange}
                  disableClearable
                  defaultValue={this.props.formData.plaats}
                  options={Nlcities}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Regular option
                    return option.city;
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
            
                    // Suggest the creation of a new value
                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        city: params.inputValue,
                      });
                    }
            
                    return filtered;
                  }}
                  getOptionSelected={(option) => {
                    return this.props.formData.plaats === option.city
                  }}
                  style={{ width: 300, margin:'0px auto'}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      variant="outlined"
                      placeholder="Plaats"
                      InputProps={{ 
                        ...params.InputProps, 
                        type: 'search',
                        startAdornment: (
                            <InputAdornment position="start">
                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                            </InputAdornment>
                        ),
                     }}
                    />
                  )}
                />

            </div>
            <Button className="custom-btn" type="button" onClick={() => this.handleSkip()}>Geen voorkeur (voornamelijk online)</Button>
          </div>
          <div className="form-bottom justify-content-end">

            <Button className="btn orange-btn" type="submit">{this.props.data.contentfulStartBoekhouderForm.step1NextButtonText}</Button>
            
          </div>
        </Form>
      </div>
    )
  }
}




FormOne.propTypes = {
  data: PropTypes.object,
}

export default function FormEleData(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulStartBoekhouderForm {
            step1Heading
            step1FieldPlaceholder
            step1NextButtonText
          }
        }
      `}
      render={data => <FormOne data={data} {...props} />}
    />
  )
}