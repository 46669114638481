import React from 'react';
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby";
import queryString from 'query-string';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Layout from '../components/layout';
import bg from "../images/blur-bg2.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeftSidebar from '../components/LeftSidebar/leftsidebar.js';
import FormOne from '../components/FormOne/formone.js';
import FormThree from '../components/FormThree/formthree.js';
import FormFour from '../components/FormFour/formfour.js';
import FormFive from '../components/FormFive/formfive.js';
import FormSix from '../components/FormSix/formsix.js';
import FormSeven from '../components/FormSeven/formseven.js';
import FormEight from '../components/FormEight/formeight.js';
import FormNine from '../components/FormNine/formnine.js';
import FormEleven from '../components/FormEleven/formeleven.js';
import '../common/src/assets/css/style';
import { Container, Row, Col, ProgressBar, Modal, Button } from 'react-bootstrap';
import '../bootstrap/dist/css/bootstrap.min.css';
import lock from "../images/lock.svg";
import { graphql, StaticQuery } from 'gatsby';
import logo from "../images/logo.svg"

class IndexPage extends React.Component {
  constructor(props){
    super(props)
    this.state = { 
      pageNumber: 1, 
      progresBar: 11.3, 
      openAlert: false, 
      show:true, 
      closePop:false,
      closeButton: true,
      formData: {
        plaats: '',
        werkzaamheden: [],
        rechtsvorm: '',
        jaaromzet: '',
        levert_u_administratie: '',
        naam_bedrijf: '',
        meer_informatie: ''
      } 
    };
  }

  componentDidMount() {
    const urlQuery = queryString.parse(this.props.location.search);
    if(urlQuery.stad) {
      this.handleChange(urlQuery.stad, 'plaats');
    }

    if(window.history.state) {
      if(window.history.state.formData) {
        var progresBar = (window.history.state.pageNumber - 1) * 11.3;
        var formData = {
          plaats: window.history.state.formData.plaats,
          werkzaamheden: window.history.state.formData.werkzaamheden,
          rechtsvorm: window.history.state.formData.rechtsvorm,
          jaaromzet: window.history.state.formData.jaaromzet,
          levert_u_administratie: window.history.state.formData.levert_u_administratie,
          naam_bedrijf: window.history.state.formData.naam_bedrijf,
          meer_informatie: window.history.state.formData.meer_informatie
        };
        var pageNumber = window.history.state.pageNumber;
        this.setState({ pageNumber, formData, progresBar })
      }
    }
  }
  
  goToPrevPage = () => {
    if (this.state.pageNumber > 1) {
      var progress = (this.state.pageNumber - 1) * 11.3;
      this.setState(state => ({ pageNumber: state.pageNumber - 1, progresBar: progress }));
    }
  }

  goToNextPage = () => {
    if (this.state.pageNumber < 10) {
      var progress = (this.state.pageNumber + 1) * 11.3;
      if (this.state.pageNumber === 10)
        progress = 100;
      this.setState(state => ({ pageNumber: state.pageNumber + 1, progresBar: progress }));
    }
  }

  goToFinalPage = () => {
    navigate('/final',
      {
        state: {
          formData: this.state.formData,
        },
      }
    );
  }

  handleChange = (val, fieldname) => {
    const { formData } = this.state;
    formData[fieldname] = val;
    this.setState({ formData });
    console.log(formData);
  }

  handleClose=()=>{
    this.setState({
      show:true,
      openAlert:true,
      closePop:true,
      closeButton: false
    })
  }

  handleCloseAll=()=>{
    this.setState({
      show:false,
      openAlert:false,
      closePop:false,
      closeButton: true
    })
  }

  warningClose=()=>{
    this.setState({
      openAlert:false,
      closeButton: true
    })
  }

  openModal=()=>{
    this.setState({
      show:true
    })
  }

  render() {
    const { pageNumber, progresBar, formData } = this.state;
    const closeContent = this.props.data.contentfulClosePopupContent;
    
    return (
      <Layout>
        <div className="main-content blue-bg" style={{ backgroundImage: `url(${bg})` }}>
          <Container>
            <div className="modeldesign">  
              <Row>

                <Col xs={12} md={4}>
                  <LeftSidebar></LeftSidebar>
                </Col>
                <Col xs={12} md={8}>
                  <Modal.Header closeButton={this.state.closeButton} onClick={this.handleClose}>
                  </Modal.Header>
                  <div className="form-wrapper">
                    <div className="text-center destop-logo">
                      <img src={logo} alt="clientImg" className="mb-3" />
                    </div>
                    {this.state.openAlert &&
                         <div className="modal-close">
                            <div>
                              <div className="mainFormContent">
                              <Modal.Header closeButton={false}>
         
                               </Modal.Header>
                                 <h2>{closeContent.title}</h2>
                                <div className="mt-5 text-center closeContent">
                                  {documentToReactComponents(JSON.parse(closeContent.body.raw))}
                                </div>                            
                                 <div className="mt-5 d-flex justify-content-center">
                                  <div className="m-2">
                                  <Link to={closeContent.cancelButtonUrl}>
                                    <Button className="btn orange-btn border-btn">{closeContent.continueBtn}</Button></Link>
                                  </div>
                                  <div className="m-2">
                                    <Button className="btn orange-btn" onClick={this.warningClose}>{closeContent.cancelBtn}</Button>
                                    <p className="button-text">{closeContent.smallText1}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="form-bottom form-bottom-no-border">
                                <div className="lock-icon-text d-flex align-items-center closeContent">
                                  <img src={lock} alt="lock-icon" />
                                  {documentToReactComponents(JSON.parse(closeContent.privacyStatement.raw))}
                                </div> 
                              </div>
                            </div>
                          </div>

                    }

                    {!this.state.openAlert &&
                    <>
                    <div className="progressBar">
                      <ProgressBar now={progresBar} />
                    </div>
                    
                    {pageNumber === 1 &&
                      <FormOne goToPrevPage={() => this.goToPrevPage} goToNextPage={() => this.goToNextPage()} handleChange={(val, fieldname) => this.handleChange(val, fieldname)} formData={formData} />
                    }
                    
                    {pageNumber === 2 &&
                      <FormThree goToPrevPage={() => this.goToPrevPage} goToNextPage={() => this.goToNextPage()} handleChange={(val, fieldname) => this.handleChange(val, fieldname)} formData={formData} />
                    }

                    {pageNumber === 3 &&
                      <FormFour goToPrevPage={() => this.goToPrevPage} goToNextPage={() => this.goToNextPage()} handleChange={(val, fieldname) => this.handleChange(val, fieldname)} formData={formData} />
                    }

                    {pageNumber === 4 &&
                      <FormFive goToPrevPage={() => this.goToPrevPage} goToNextPage={() => this.goToNextPage()} handleChange={(val, fieldname) => this.handleChange(val, fieldname)} formData={formData} />
                    }

                    {pageNumber === 5 &&
                      <FormSix goToPrevPage={() => this.goToPrevPage} goToNextPage={() => this.goToNextPage()} handleChange={(val, fieldname) => this.handleChange(val, fieldname)} formData={formData} />
                    }

                    {pageNumber === 6 &&
                      <FormSeven goToPrevPage={() => this.goToPrevPage} goToNextPage={() => this.goToNextPage()} handleChange={(val, fieldname) => this.handleChange(val, fieldname)} formData={formData} />
                    }

                    {pageNumber === 7 &&
                      <FormEight goToPrevPage={() => this.goToPrevPage} goToNextPage={() => this.goToFinalPage()} handleChange={(val, fieldname) => this.handleChange(val, fieldname)} formData={formData} />
                    }

                    {pageNumber === 8 &&
                      <FormNine goToPrevPage={() => this.goToPrevPage} goToFinalPage={() => this.goToFinalPage()} handleChange={(val, fieldname) => this.handleChange(val, fieldname)} formData={formData} />
                    }
                    </>
                    }


                  </div>


                </Col>
              
            </Row>
            </div>
          </Container>
        </div>

      </Layout>
    )
  }

}



IndexPage.propTypes = {
  data: PropTypes.object,
}


export default function data(props) {
  return (
    <StaticQuery
      query={graphql`
      query {
          contentfulClosePopupContent {
            body {
              raw
            }
            cancelBtn
            cancelButtonUrl
            continueBtn
            title
            privacyStatement {
              raw
            }
            smallText1
          }
        }
      `}
      render={data => <IndexPage data={data} {...props} />}
    />
  )
}