import { Button, Form, FormControl, InputGroup } from "react-bootstrap"
import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { graphql, StaticQuery } from 'gatsby';


class FormSeven extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: ''
    }
  }
  handleChange = (e) => {
    this.props.handleChange(e.target.value, 'naam_bedrijf');
  }
  handleSubmit = (e) => {
    if(this.props.formData.naam_bedrijf === ''){
      this.setState({
      error:'Invoer is nodig',
      })
    }
    else{
      this.props.goToNextPage();
    }
  }
  render() {
    const data = this.props.data.contentfulStartBoekhouderForm;
    
    return (
    <div className="">
      <div className="mainFormContent">
        <h2>{data.step6Heading}</h2>
        <div className="centerForm">
          <div style={{ color: 'red' }}>{this.state.error}</div>
          <InputGroup className="mb-3">
            <FormControl
              aria-describedby="basic-addon1"
              onChange={this.handleChange}
            />
          </InputGroup>
        </div>
         
      </div>
      <div className="form-bottom">
          <Button className="btn orange-btn transparent-btn float-left" onClick={this.props.goToPrevPage()}><FontAwesomeIcon icon={faArrowLeft} className="mr-1" />Vorige</Button>
          {<Button className="btn orange-btn" type="button" onClick={() => this.handleSubmit()}>{data.step6NextButtonText}</Button>}
      </div>
    </div>
    )
  }
}

FormSeven.propTypes = {
  data: PropTypes.object,
}

export default function FormEleData(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulStartBoekhouderForm {
            step6Heading
            step6NextButtonText
          }
        }
      `}
      render={data => <FormSeven data={data} {...props} />}
    />
  )
}