import { Button, Form } from "react-bootstrap"
import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { graphql, StaticQuery } from 'gatsby';


class FormSix  extends React.Component {
    constructor(props){
        super(props);
        this.state={
            error:'',
            term:false
        }
    }
    handleChangeRadio = (val) =>{
        this.props.handleChange(val, 'levert_u_administratie');
    }

    handleSubmit = (e) =>{
        if(this.props.formData.levert_u_administratie === ''){
            this.setState({
            error:'Invoer is nodig',
            })
        }
        else{
            this.props.goToNextPage();
        }
    }

    render(){
        const data = this.props.data.contentfulStartBoekhouderForm;

        return (
        <div className="">
            <div className="mainFormContent">
                <h2>{data.step5Heading}</h2>
                <div className="centerForm">
                    <div style={{color:'red'}}>
                        {this.state.error}
                    </div> 

                    {data.step5FieldOptions.map((item, index) => (
                    <div className="form-check">
                        <input className="form-check-input" type="radio" id={`radio6-${index}`}  onChange={() => this.handleChangeRadio(item.choiceText)} checked={this.props.formData.levert_u_administratie === item.choiceText} />
                        <label className="form-check-label" for={`radio6-${index}`}>{item.choiceText}</label>
                    </div>
                    ))}
                </div>
            </div>
            <div className="form-bottom">
                <Button className="btn orange-btn transparent-btn float-left" onClick={this.props.goToPrevPage()}><FontAwesomeIcon icon={faArrowLeft} className="mr-1" />Vorige</Button>
                    
                {<Button className="btn orange-btn" type="button" onClick={() => this.handleSubmit()}>{data.step5NextButtonText}</Button>}
           
            </div>
        </div>
        )
    }
}

FormSix.propTypes = {
    data: PropTypes.object,
  }

export default function FormEleData(props) {
    return (
      <StaticQuery
        query={graphql`
            query {
                contentfulStartBoekhouderForm {
                    step5FieldOptions {
                      choiceText
                    }
                    step5Heading
                    step5NextButtonText
                }
            }
        `}
        render={data => <FormSix data={data} {...props} />}
      />
    )
}